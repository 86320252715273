import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
// import { Products } from "./components/products";
import { DiscountBanner } from "./components/discountBanner";
import { AgeVerificationPopup } from "./components/ageVerificationPopup";
import { ProductsListGallery } from "./components/productsGallery";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [isAgeVerified, setIsAgeVerified] = useState(
    localStorage.getItem("isAgeVerified") === "true"
  );

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const handleAgeVerification = (isVerified) => {
    if (isVerified) {
      localStorage.setItem("isAgeVerified", "true");
      setIsAgeVerified(true);
    } else {
      alert("Trebuie să ai peste 18 ani pentru a accesa acest site.");
    }
  };

  return (
    <>
      {!isAgeVerified && (
        <AgeVerificationPopup onConfirm={handleAgeVerification} />
      )}
      <div style={{ filter: isAgeVerified ? "none" : "blur(5px)" }}>
        <Navigation />
        <DiscountBanner />
        <Header data={landingPageData.Header} />
        <About data={landingPageData.About} />
        <Services data={landingPageData.Services} />
        <ProductsListGallery data={landingPageData.Products} />
        <Gallery data={landingPageData.Gallery} />
        <Contact data={landingPageData.Contact} />
      </div>
    </>
  );
};

export default App;

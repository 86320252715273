import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { useMediaQuery, useTheme } from "@mui/material";

export const ProductsListGallery = (props) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); 
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md")); 
  
    const getCols = () => {
      if (isSmallScreen) return 2; 
      if (isLargeScreen) return 3; 
    };
  return (
    <div id="products" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Produsele noastre</h2>
        </div>
        <Box sx={{ height:'900px', overflowY: "scroll" }} className="scrollbar" id="scrollbar-design">
          <ImageList variant="masonry" cols={getCols()} gap={8}>
            {props?.data?.map((item) => (
              <ImageListItem key={item.img} >
                <img
                  srcSet={`${item.img}`}
                  src={`${item.img}`}
                  alt={item.title}
                  loading="lazy"
                  style={{
                    width: "100%", 
                    height: "auto", 
                    maxHeight: "550px",
                    objectFit: "cover",
                  }}
                />
                <ImageListItemBar
                  position="bottom"
                  title={`${item.name}`}
                  className="product-subtitle"
                  subtitle={`${item.price}`}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </div>
    </div>
  );
};

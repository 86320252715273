import React from "react";

export const DiscountBanner = () => {
  return (
    <div className="discount-banner">
      <p>🎉 Reduceri de 5-10% pentru persoane fizice sau juridice! Mai multe detalii in showroom sau telefonic. 🎉</p>
    </div>
  );
};


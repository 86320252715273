import React from "react";

const popupStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    popup: {
      backgroundColor: "#F4D9D0",
      padding: "24px",
      borderRadius: "10px",
      boxShadow: "0 2px 10px rgba(14, 117, 45, 0.1)",
      textAlign: "center",
    },
    logo: {
      width: "250px",
      height: "250", 
    },
    
    buttons: {
      marginTop: "40px",
      display: "flex",
      justifyContent: "space-around",
      fontFamily: "'Work Sans', sans-serif",
      fontSize: '24px'
      
    },
    buttonYes: {
      padding: "16px 20px",
      backgroundColor: "#1A926C",
      color: "white",
      border: "none",
      borderRadius: "8px",
      cursor: "pointer",
      marginLeft:"12px"
    },
    buttonNo: {
      padding: "16px 20px",
      backgroundColor: "#921A40",
      color: "white",
      border: "none",
      borderRadius: "8px",
      cursor: "pointer",
    },
};

export const AgeVerificationPopup = ({ onConfirm }) => {
  return (
    <div style={popupStyles.overlay}>
      <div style={popupStyles.popup} className="popup">
      <img
          src="/img/final-logos/logo-orizontal-transparent-negru-transformed.png"
          alt="Logo Vinoteca Terre di Verona"
          style={popupStyles.logo}
        />
        <h3>Pentru a accesa acest site, trebuie să ai minim 18 ani.</h3>
        <div style={popupStyles.buttons}>
        <button
            onClick={() => (window.location.href = "https://www.google.com")}
            style={popupStyles.buttonNo}
          >
            Nu am 18 ani.
          </button>
          <button onClick={() => onConfirm(true)} style={popupStyles.buttonYes}>
            Am peste 18 ani.
          </button>
        </div>
      </div>
    </div>
  );
};
